<template lang="pug">
span.label.label-orange
  | {{ text }}
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.label {
  @apply inline-block px-5 py-2 rounded-full;
}

.label-orange {
  @apply text-orange-500 bg-orange-200;
}
</style>
