<template lang="pug">
#bloig.pt-8
  h1.aspire-title.text-aspire-blue-2.mx-auto
    | {{ $t('blog.title') }}
  .grid.gap-8.px-2.mt-6(
    class="xs:px-5 sm:container sm:mx-auto lg:grid-cols-3 xl:grid-cols-4"
  )
    div(class="lg:col-span-2 xl:col-span-3")
      .grid.gap-4(
        class="sm:grid-cols-2 lg:col-span-2 lg:gap-x-6 lg:gap-y-10 xl:col-span-3"
      )
        CardBlog(
          v-for="(post, idx) in posts",
          :key="`post-${post.id}`",
          :post="post"
        )
    .flex.flex-col.space-y-8(class="sm:container sm:mx-auto")
      Search(@change="find($event)")
      LatestPosts.bg-white.rounded-lg.shadow-all.p-4
</template>

<script>
// Component
import CardBlog from "@/components/CardBlog.vue";
import Search from "@/components/Search.vue";
import LatestPosts from "@/components/LatestPost.vue";

// Data
import Posts from "@/posts.json";

export default {
  components: {
    CardBlog,
    Search,
    LatestPosts,
  },
  data() {
    return {
      posts: [...Posts.data].reverse(),
    };
  },
  methods: {
    find(searchTerm) {
      if (searchTerm === "") {
        this.posts = Posts.data;
      } else {
        this.posts = Posts.data.filter(
          (post) =>
            post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            post.categories.find(
              (category) => category.toLowerCase() === searchTerm.toLowerCase()
            )
        );
      }
    },
  },
};
</script>

<style scoped>
hr {
  height: 1px;
}
</style>
