<template lang="pug">
.w-full.rounded.overflow-hidden.shadow-lg(class="hover:shadow-xl")
  router-link.img-hover-zoom(
    :to="{ name: 'post', params: { id: post.id } }",
    tag="div"
  )
    LazyImg.w-full.object-top.object-cover.h-250-px.cursor-pointer(
      :lazy-src="post.image"
    )
  .px-4.py-6.rounded-lg
    div
      router-link.font-bold.text-xl.mb-2.text-aspire-blue-2.cursor-pointer(
        class="hover:text-aspire-yellow-1",
        :to="{ name: 'post', params: { id: post.id } }"
      )
        | {{ post.title }}
      .grid.grid-cols-2.divide-x.divide-gray-400.w-64.mt-2.w-full
        .text-center.text-xs.text-gray-500.justify-self-start
          span
            | {{ post.date }}
        a.facebook.rounded.p-1.w-5.h-5.justify-self-end(
          :href="`https://www.facebook.com/sharer/sharer.php?u=${postUrl}`",
          target="_blank"
        )
          SvgIcon(icon="facebook", :classes="['w-3', 'h-3']")
      hr.w-full.border-0.bg-gray-200.h-1.mt-3.mb-2
      p.text-gray-700.text-sm.py-2.max-lines.leading-loose(
        v-html="extractContent(post.text)"
      )
      hr.w-full.border-0.bg-gray-200.h-1.my-3
  //
    .flex.flex-wrap.justify-between.items-center
      .flex.space-x-2.items-center.mt-1
        SvgIcon.flex-shrink-0.text-aspire-yellow-1(
          icon="tag",
          :classes="['w-4', 'h-4']"
        )
        .inline-flex.flex-wrap
          span.bg-gray-200.rounded-full.px-3.py-1.text-xs.font-semibold.text-gray-700.mr-1.mt-1(
            v-for="(category, idx) in post.categories",
            :key="`category-${idx}`",
            class="last:mr-0"
          )
            | # {{ category }}
      .flex.space-x-2.items-center.mt-4
        SvgIcon.text-aspire-yellow-1(icon="share", :classes="['w-4', 'h-4']")
        a.facebook.rounded.p-1(
          :href="`https://www.facebook.com/sharer/sharer.php?u=${postUrl}`",
          target="_blank"
        )
          SvgIcon(icon="facebook", :classes="['w-3', 'h-3']")
</template>

<script>
// Components
import LazyImg from "@/components/LazyImg.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import Label from "@/components/Label.vue";

export default {
  components: {
    LazyImg,
    SvgIcon,
    Label,
  },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    postUrl() {
      return `${window.location.origin}/post/${this.post.id}`;
    },
  },
  methods: {
    extractContent(html) {
      return new DOMParser()
        .parseFromString(html, "text/html")
        .querySelector("p").outerHTML;
    },
  },
};
</script>

<style scoped>
hr {
  height: 1px;
}

.facebook {
  background-color: #36528e;
  color: white;
}

.max-lines {
  overflow: hidden;
  height: 98px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

/* [1] The container */
.img-hover-zoom {
  height: 250px; /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform 0.5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
}
</style>
