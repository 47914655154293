<template lang="pug">
.relative.flex.items-center.w-full
  SvgIcon.absolute.text-gray-300(
    icon="search",
    style="left: 10px",
    :classes="['w-4', 'h-4']"
  )
  input.w-full.border.rounded-lg.h-12.pl-8.pr-4.text-gray-700.outline-none.text-sm(
    type="text",
    :placeholder="$t('search')",
    class="focus:outline-none focus:shadow-outline",
    @change="$emit('change', $event.target.value.trim())"
  )
</template>

<script>
// Components
import SvgIcon from "@/components/SvgIcon.vue";

export default {
  components: {
    SvgIcon,
  },
};
</script>

<style scoped></style>
