<template lang="pug">
#latest-post
  h3.font-bold.text-xl.text-aspire-blue-2
    | {{ $t('blog.latestPost') }}
  hr.border-0.bg-gray-200.my-4
  .grid.gap-4
    router-link.post-container.flex.cursor-pointer(
      v-for="(post, idx) in posts",
      :key="`latest-post-${post.id}`",
      :to="{ name: 'post', params: { id: post.id } }",
      tag="div"
    )
      LazyImg.flex-shrink-0.w-20.h-20.object-cover.object-center(
        :lazy-src="post.image"
      )
      .ml-4
        h6.font-bold.text-sm
          | {{ post.title }}
        p.text-gray-600.text-xs.mt-2
          | {{ post.date }}
</template>

<script>
// Components
import LazyImg from "@/components/LazyImg.vue";

// Data
import Posts from "@/posts.json";

export default {
  components: {
    LazyImg,
  },
  props: {
    max: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    posts() {
      return Posts.data.slice(-3).reverse();
    },
  },
};
</script>

<style scoped>
hr {
  height: 1px;
}

.post-container:hover > div > h6 {
  @apply text-aspire-yellow-1;
}
</style>
